.swal-modal .swal-text {
  text-align: center;
}

.text-center {
  text-align: center;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
